<template>
  <div class="card">
    <Toast>
      <template #message="slotProps">
        <span :class="iconClass"></span>
        <div class="p-toast-message-text">
          <span class="p-toast-summary" v-html="slotProps.message.summary"></span>

        </div>
      </template>
    </Toast>
    <h1 v-if="!this.modal_mode">Crear pieza</h1>

    <div class="">
      <div class="">
        <div class="">
          <div class="formgrid grid">
            <div class="field col-2">
              <label for="detail">Código</label>
              <InputText v-model="form.code" id="detail" type="text" class="inputfield w-full" />
            </div>
            <div class="field col-3">
              <label for="detail">Nombre de la pieza</label>
              <InputText v-model="form.name" id="detail" type="text" class="inputfield w-full" />
            </div>
            <div class="field col-2">
              <label for="detail">Plano</label>

              <input type="file" id="files" name="files[]" class="inputfield w-full" v-on:change="onFileChange" />
            </div>
            <div class="field col-1">
              <label for="detail">Numero de plano</label>
              <InputText v-model="plane_number" id="detail" type="text" class="inputfield w-full" />
            </div>
            <div class="field col-1">
              <label for="detail">Revisión</label>
              <InputText v-model="plane_rev" id="detail" type="text" class="inputfield w-full" />
            </div>
            <div class="field col-2">
              <label for="detail">U$D Banco Nación</label>
              <InputNumber mode="decimal" :minFractionDigits="2" v-model="dolar_bna" id="detail" type="text"
                class="inputfield" />
              <div>
                <small> U$D Sugerido: {{ this.dolar_suggested }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <h2>
            COSTO CALCULADO:
            <span class="calculated_price">
              {{ toArs(piece_cost) }} / {{ toUsd(piece_cost) }}</span>
          </h2>
        </div>
        <div class="grid">
          <div class="col-5">
            <div class="formgrid grid">
              <div class="field col-12">
                <h4>Mano de obra</h4>
              </div>
              <div class="field col-3">
                <label for="detail">Costo M/O. $</label>
                <InputText v-model="costos.vmod" id="detail" type="text" class="inputfield w-full" />
              </div>
              <div class="field col-3">
                <label for="detail">Tiempo Promedio</label>
                <InputText v-model="costos.mod" id="detail" type="text" class="inputfield w-full" />
                <!-- <small> PPH: {{ costos.vmod }} Costo calculado : {{ toArs((costos.vmod / 60) * costos.mod) }}</small> -->
              </div>
              <div class="field col-3">
                <label for="detail">Inc</label>
                <InputText v-model="costos.inc" id="detail" type="text" class="inputfield w-full" />
                %
              </div>

              <div class="field col-1">
                <label for="detail">Subtotal</label>
                <div class="subtotal">
                  {{ toArs(sub_mod) }}
                </div>
              </div>
            </div>

          </div>
          <div class="col-7">
            <h4>Materiales</h4>
            <div class="formgrid">
              <div class="grid" v-for="(t, index) in this.mprimas" :key="index">
                <div class="field col-4">
                  <label for="detail">Tipo</label>
                  <InputText v-model="t.tipo" id="detail" type="text" class="inputfield w-full" />
                </div>

                <div class="field col-3">
                  <label for="detail">Peso Bruto</label>
                  <InputNumber mode="decimal" :minFractionDigits="2" v-model="t.pesobruto" id="detail" type="text"
                    class="inputfield w-full" />
                </div>
                <div class="field col-3">
                  <label for="detail">$ x Kg.</label>
                  <InputNumber mode="decimal" :minFractionDigits="2" v-model="t.ppkg" id="detail" type="text"
                    class="inputfield w-full" />
                </div>
                <div class="field col-1 flex">

                  <Button @click="delItemMP(index)" icon="pi pi-trash"
                    class="p-button-sm p-button-danger align-self-end" />
                </div>

                <div class="field col-1">
                  <label for="detail">Subtotal</label>
                  <strong>
                    {{ toArs(t.ppkg * t.pesobruto) }}
                  </strong>
                </div>
              </div>
              <div class="formgrid grid">

                <div class="field col-6">
                  <Button @click="addItemMP()" label="Agregar" icon="pi pi-plus" class="p-button-sm p-button-success" />
                </div>
                <div class="field col-6">
                  <div class="subtotal ">
                    {{ toArs(sub_mprimas) }}
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="field col-12">
                <h4>Terceros</h4>
              </div>
              <div class="col-12">
                <div class="formgrid" v-for="(t, index) in this.terceros" :key="index">
                  <div class=" grid">
                    <div class="field col-2">
                      <label for="detail">PROVEEDOR</label>

                      <AutoComplete
                  v-model="selectedClient"
                  :suggestions="filteredClients"
                  @complete="searchClient($event)"
                  field="name"
                  :dropdown="true"
                  @item-select="t.provider_id = selectedClient.id"
                  id="base"
                  class="inputfield w-full"
                />

                    </div>
                    <div class="field col-1">
                      <label for="detail">FECHA</label>
                      <Calendar v-model="t.date" id="detail" type="text" class="inputfield w-full" />
                    </div>
                    <div class="field col-2">
                      <label for="detail">DESCRIPCION</label>
                      <InputText v-model="t.name" id="detail" type="text" class="inputfield w-full" />
                    </div>
                    <div class="field col-2">
                      <label for="detail">COSTO</label>
                      <InputNumber mode="decimal" :minFractionDigits="2" v-model="t.cost" id="detail" type="text"
                        class="inputfield w-full" />
                    </div>
                    <div class="field col-1">
                      <label for="detail">ADJUNTO</label>
                      <input type="file" id="filesterc" name="filesterc[]" class="inputfield w-full" v-on:change="onFileChangeTerc" />
                    </div>
                    <div class="field col-2">
                      <label for="detail">COMENTARIO</label>
                      <InputText v-model="t.coment" id="detail" type="text" class="inputfield w-full" />
                    </div>
                    <div class="field col-1 flex">
                      <Button @click="delItem(index)" id="delete" icon="pi pi-trash"
                        class="p-button-sm p-button-danger align-self-end" />
                    </div>
                  </div>
                </div>

                <div class="formgrid grid">

                  <div class="field col-6">
                    <Button @click="addItem()" label="Agregar" icon="pi pi-plus" class="p-button-sm p-button-success" />
                  </div>
                  <div class="field col-10">
                    <div class="subtotal">
                      {{ toArs(sub_terc) }}
                    </div>
                  </div>

                </div>

              </div>
        </div>

        <hr />
        <div v-if="!this.modal_mode">
          <h4>Operaciones</h4>

          <div v-if="operaciones && operaciones.length">
            <div v-for="(operacion, index) in operaciones" :key="index" class="formgrid grid">
              <div class="col-5 field">
                <label for="detail">Descripción</label>
                <InputText v-model="operacion.name" id="detail" type="text" class="inputfield w-full" />
              </div>
              <div class="col-5 field">
                <label for="detail">Tiempo estimado</label>
                <InputNumber mode="decimal" :minFractionDigits="2" v-model="operacion.estimated" id="detail" type="text"
                  class="inputfield w-full" />
              </div>

              <div class="col-2 field flex">
                <Button @click="delOper(index)" id="delete" icon="pi pi-trash"
                  class="p-button-sm p-button-danger align-self-end" />
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-sm">
              <InlineMessage severity="error">No hay operaciones para la pieza, agreguelas para
                continuar.</InlineMessage>
            </p>
          </div>

          <div class="mt-3 formgrid grid">
            <div class="field col-5">
              <label for="detail">Descripción</label>
              <!-- <InputText
                    v-model="operation"
                    id="detail"
                    type="text"
                    class="inputfield w-full"
                  /> -->
              <AutoComplete v-model="operation" :dropdown="true" :suggestions="filteredOperations"
                @complete="searchOperations($event)" class="inputfield w-full" />
            </div>
            <div class="field col-5">
              <label for="detail">Tiempo estimado</label>
              <InputText v-model="operation_estimated" id="detail" type="text" class="inputfield w-full" />
            </div>
            <div class="field col-2 flex">
              <label for="detail"> </label>
              <Button @click="addOperation()" icon="pi pi-plus" class="p-button-sm p-button-success align-self-end" />
            </div>
          </div>
        </div>

        <div class="formgrid grid">
          <div class="field col">
            <Button @click="save()" label="Guardar" id="detail" type="text"
              class="p-button-success inputfield w-full" />
          </div>
        </div>
        <!-- items -->

      </div>
    </div>
  </div>
</template>

<script>
import CrudService from "./../../services/crud.service.js";

export default {
  props: ["piece_id", "modal_mode"],
  data() {
    return {
      loaded: false,
      materiales: [],
      operaciones: [],
      operation: "",
      operation_estimated: "",
      dolar_suggested: 1,
      dolar_bna: 1,
      selectedMaterial: null,
      selectedPlanes: null,
      files: null,
      filesterc: null,
      plane_number: "",
      plane_rev: "",
      planes: [
        {
          name: "PLANO 1",
          id: 1,
        },
        {
          name: "PLANO 2",
          id: 2,
        },
        {
          name: "PLANO 3",
          id: 3,
        },
      ],
      filteredMaterials: [],
      filteredOperations: [],
      terceros: [],
      mprimas: [],
      pph: 100,
      costos: {
        vmod: 0,
        mod: 5,
        tmp: 1,
        mpd: 1,
      },
      selectedInstrument: null,
      filteredInstruments: null,
      instrumentos: null,
      form: {
        code: "",
        costos: [
          {
            mod: null,
            mpd: null,
            tmp: null,
          },
        ],
        variables: [],
      },
      
      Clients: null,
      filteredClients: null,
      selectedClient: null,
    };
  },
  mounted() {
    CrudService.getCRUD("api/proveedor").then(
      (data) => (this.clients = data)
    );

    CrudService.getCRUD("api/instrumento").then(
      (data) => (this.instrumentos = data)
    );
    CrudService.getCRUD("api/costos").then((data) => {
      this.pph = data.pph_operator;
      this.dolar_suggested = data.bna.dolar.value;
    });


    let piece_id = this.piece_id;
    if (!piece_id) {
      piece_id = this.$route.params.id;
    }


    if (this.modal_mode == "create" && this.prodname) {
      this.form.name = this.prodname;
    }
  },
  created() { },
  computed: {
    sub_mprimas() {
      let total = 0;
      for (let i = 0; i < this.mprimas.length; i++) {
        total += this.mprimas[i].ppkg * this.mprimas[i].pesobruto;
      }
      return total;
    },
    sub_terc() {
      let total = 0;
      for (let i = 0; i < this.terceros.length; i++) {
        total += parseFloat(this.terceros[i].cost);
      }
      return parseFloat(total);
    },
    sub_mod() {
      let total = 0;
      total = (this.costos.vmod / 60) * this.costos.mod;

      // add ten percent
      total = total * parseFloat("1." + this.costos.inc);

      return parseFloat(total);
    },
    piece_cost() {
      let total = 0;

      total += this.sub_mprimas;
      total += this.sub_terc;
      total += this.sub_mod;

      return total;
    },
  },
  methods: {
    searchClient(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredClients = [...this.clients];
        } else {
          this.filteredClients = this.clients.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    searchOperations(event) {
      if (event.query.length == 0) {
        CrudService.getCRUD("api/operacion/search/all").then(
          (data) => (this.filteredOperations = data)
        );
      } else {
        CrudService.getCRUD("api/operacion/search/" + event.query).then(
          (data) => (this.filteredOperations = data)
        );
      }

    },
    addOperation() {
      this.operaciones.push({
        name: this.operation,
        estimated: this.operation_estimated,
      });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.files = files;
    },
    onFileChangeTerc(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.filesterc = files;
    },
    searchMaterials(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMaterials = [...this.materiales];
        } else {
          this.filteredMaterials = this.materiales.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    toArs(value) {
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    toUsd(value) {
      value = value / this.dolar_bna;
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "USD",
      });
    },
    delItem(index) {
      this.terceros.splice(index, 1);
    },
    delVariable(index) {
      this.form.variables.splice(index, 1);
    },
    delOper(index) {
      this.operaciones.splice(index, 1);
    },
    delItemMP(index) {
      this.mprimas.splice(index, 1);
    },

    addItem() {
      this.terceros.push({
        name: "",
        cost: 1,
      });
    },
    addItemMP() {
      this.mprimas.push({
        name: "",
        pesobruto: 0,
        ppkg: 0,
      });
    },
    save() {
      let formData = new FormData();
      let piece_id = this.piece_id;
      if (!piece_id) {
        piece_id = this.$route.params.id;
      }

      formData.append("id", piece_id);
      formData.append("code", this.form.code);
      formData.append("name", this.form.name);

      formData.append("costos", JSON.stringify(this.costos));

      formData.append("variables", JSON.stringify(this.form.variables));

      formData.append("operaciones", JSON.stringify(this.operaciones));

      formData.append("terceros", JSON.stringify(this.terceros));



      formData.append("mprimas", JSON.stringify(this.mprimas));

      formData.append("inc", this.costos.inc);

      formData.append("vmod", this.costos.vmod);
      formData.append("mod", this.costos.mod);
      formData.append("dolar_bna", this.dolar_bna);

      // check if this.files length is greater than 0, means we have selected at least one file
      formData.append("plane_number", this.plane_number);
      formData.append("plane_rev", this.plane_rev);

      if (this.files) {
        Array.from(this.files).forEach(function (file) {
          formData.append("files[]", file);
        });
      }

      // remove undefined values
      for (var pair of formData.entries()) {
        console.log(pair)
        if (pair[1] == "undefined" || pair[1] == undefined || pair[1] == "" || pair[1] == null || pair[1] == "null") {
          formData.delete(pair[0]);
        }
      }

      CrudService.createCRUD("api", "pieza", formData)
        .then((data) => {
          if (data.status == "success") {
            this.$toast.add({
              severity: "success",
              summary: "Éxito!",
              detail: "Creada correctamente.",
              life: 3000,
            });

            if (this.modal_mode == "create") {
              this.$emit("create_piece", data);
            } else if (this.modal_mode == "edit") {
              this.$emit("update_piece", data);
              //alert('cerrar modal')
            } else {
              this.$router.push("/piezas");
            }
          } else {

            // forech error.data.errors
            for (var key in data.data.errors) {
              if (data.data.errors.hasOwnProperty(key)) {
                this.$toast.add({
                  severity: "error",
                  //summary: "Error!",
                  summary: data.data.errors[key][0],
                  life: 3000,
                });
              }
            }


          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchInstruments(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredInstruments = [...this.instrumentos];
        } else {
          this.filteredInstruments = this.instrumentos.filter((instru) => {
            return instru.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
  },
};
</script>
<style scoped>
.subtotal {
  font-weight: bold;
  font-size: 20px;
  text-align: end;
}
</style>